const digiclock = {
	init() {
		if (!$q('.digiclock').first) return

		let n = 163
		let pop = 0

		$q('.digiclock .num', (el, i) => {
			pop = String(n).padStart(7, '0')
			this.tick(el, Number(0))
			this.tick(el, Number(pop[i]))
		})
	},
	tick(mc, i) {
		let elNum = parseInt(mc.dataset.num)

		if (isNaN(elNum)) elNum = 0

		mc.innerHTML = '<span>' + i + '</span>'

		// set numbers
		if (elNum === i || i == 0) return
		mc.dataset.num = i
	},
	randomNumber(min, max) {
		return Math.random() * (max - min) + min
	},
}

export default digiclock

import { TweenMax, Bounce, Power1, Power4 } from 'gsap'

const flipclock = {
	init() {
		if (!$q('.flipclock').first) return

		// set 3d transforms
		// TweenMax.set('#clock', { perspective: 1500, scale: 0.7125 })
		TweenMax.set('.flipclock .upper', { rotationX: 0.01, transformOrigin: '50% 100%' })
		TweenMax.set('.flipclock .lower', { rotationX: 0.01, transformOrigin: '50% 0%' })

		let n = 1250633
		let pop = 0

		$q('.flipclock .num', (el, i) => {
			pop = String(n).padStart(7, '0')
			this.tick(el, Number(0))
			setTimeout(() => this.tick(el, Number(pop[i])), 1250 / (i + 1))
		})

		setInterval(() => {
			$q('.flipclock .num', (el, i) => {
				n += parseInt(this.randomNumber(0, 150))
				pop = String(n).padStart(7, '0')
				setTimeout(() => this.tick(el, parseInt(pop[i])), 1250 / (i + 1))
			})
		}, this.randomNumber(3000, 5000))
	},
	tick(mc, i, toZero = false) {
		let elNum = parseInt(mc.dataset.num)

		if (isNaN(elNum)) elNum = 0

		mc.childNodes[3].innerHTML = '<span>' + elNum + '</span>' //start upper
		mc.childNodes[5].innerHTML = '<span>' + elNum + '</span>' //start lower
		mc.childNodes[1].innerHTML = '<span>' + i + '</span>' //end upper
		mc.childNodes[7].innerHTML = '<span>' + i + '</span>' //end lower

		// set numbers
		if (elNum === i || i == 0) return
		mc.dataset.num = i

		// animate tick
		TweenMax.fromTo(mc.childNodes[1], 0.3, { alpha: 0 }, { alpha: 1, ease: Power4.easeIn })
		TweenMax.fromTo(mc.childNodes[3], 0.3, { rotationX: 0, background: 'linear-gradient(0deg, var(--bs-dark), var(--bs-primary) 15%)' }, { rotationX: -90, ease: Power1.easeIn })
		TweenMax.fromTo(mc.childNodes[7], 0.5 + 0.2 * Math.random(), { rotationX: 90 }, { rotationX: 0, ease: Bounce.easeOut, delay: 0.3 })
		TweenMax.fromTo(mc.childNodes[5], 0.6, { alpha: 1 }, { alpha: 0, ease: Bounce.easeOut, delay: 0.3 })
	},
	randomNumber(min, max) {
		return Math.random() * (max - min) + min
	},
}

export default flipclock

import '../scss/styles.scss'

// Lib
// import './lib/polyfills'
import './lib/q'

// Bootstrap
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/collapse'

// Alpine
import Alpine from 'alpinejs'

// Views
import glob from './views/glob'

class global {
	constructor() {
		window.Alpine = Alpine
		Alpine.start()
	}

	start() {
		glob.init()
	}

	onLoad() {
		document.body.classList.add('loaded')
		glob.load()
	}

	preLoad() {}

	loadComplete() {}
}
const app = new global()

// Window Events
window.addEventListener('DOMContentLoaded', () => app.start(), false)
window.addEventListener(
	'load',
	() => {
		setTimeout(() => {
			app.onLoad()
			app.loadComplete()
		}, 1000)
	},
	false
)
window.addEventListener(
	'scroll',
	() => {
		const scrollTop = window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop

		if (scrollTop > 0) document.body.classList.add('has-scrolled')
		else document.body.classList.remove('has-scrolled')
	},
	false
)

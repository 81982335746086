// Views

// Components
import charts from '../components/charts'
import digiclock from '../components/digiclock'
import flipclock from '../components/flipclock'

// Class

const glob = {
	init() {
		flipclock.init()
		digiclock.init()
		charts.init()
	},
	load() {},
}
export default glob

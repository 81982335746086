import Chart from 'chart.js/auto'

const charts = {
	init() {
		if (!$q('#chart-total').first) return

		const labels = ['Aug 17', 'Aug 18', 'Aug 19', 'Aug 20', 'Aug 21', 'Aug 22', 'Aug 23']
		const data = {
			labels: labels,
			datasets: [
				{
					label: 'My First Dataset',
					data: [12, 50, 10, 20, 100, 75, 200],
					fill: false,
					borderColor: '#85893C',
					tension: 0,
				},
			],
		}

		var ctx = $q('#chart-total').first.getContext('2d')

		Chart.defaults.elements.line.borderWidth = 10
		Chart.defaults.elements.point.borderWidth = 0
		Chart.defaults.elements.point.hoverBorderWidth = 8
		Chart.defaults.elements.point.hoverBorderColor = 'white'
		Chart.defaults.elements.point.hoverBackgroundColor = 'red'
		Chart.defaults.elements.point.hoverRadius = 10

		var barChart = new Chart(ctx, {
			type: 'line',
			data,
			options: {
				responsive: true,
				interaction: {
					mode: 'index',
					intersect: false,
				},
				stacked: false,
			},
		})
	},
}

export default charts
